.container-slider {
    margin: auto;
    max-width: 40vw;
    height: 50vh;
    /* margin: 100px auto 0; */
    position: relative;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
}
  .slide img {
      width: 100%;
      height: 100%;
    object-fit: contain;
}
  .active-anim {
      opacity: 1;
    }
    
    .btn-slide {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: inherit;
    border: none;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
  
  .btn-slide img {
      width: 25px;
      height: 25px;
    pointer-events: none;
}
.prev {
    top: 50%;
    left: -15%;
    transform: translateY(-60%);
}
.next {
    top: 50%;
    right: -15%;
    transform: translateY(-60%);
  }
  
  @media screen and (max-width: 700px){
    .container-slider {
      max-width:66vw;
    }
  }