.cash-register-page {
    min-height: 80vh;
    padding: 3% 0 5% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../styles/images/circuit.png"), url("../../styles/images/computing.png"), url("../../styles/images/integrated.png"), url("../../styles/images/chipset.png");
    background-repeat: no-repeat;
    background-position: right -5% top 5%, left -15% top 35%, right -41% top 62%, left -28% top 90% ;
    background-size: 15%, 28%, 40%, 30%;
}

.cash-register-page section {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}

.cash-register-page h1 {
    color: var(--primary-color);
    font-size: 2.2em;
    text-align: center;
    margin-bottom: 5%;
}

.cash-regist-card {
    padding-left: 10%;
    width: 50vw;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
}

.cash-text {
    font-weight: bold;
    width: 45vw;
    margin-bottom: 3%
}

.cash-regist-card img {
    width: 15vw;
}

@media screen and (max-width: 534px) {

    .cash-text {
        width: 60vw;
    }

    .cash-register-page h1 {
        padding: 3%;
        font-size: 1.7em;
    }

    .cashregist-card {
        flex-direction: column;
        margin-bottom: 25%;
    }

    .cash-regist-card {
        padding-left:0;
    }

    .cash-regist-card h3 {
        font-size:1.1em;
        margin-bottom: 5%;;
    }

    .cash-regist-card img {
        width: 50vw;
    }
}