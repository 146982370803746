.softwares {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../styles/images/circuit.png"), url("../../styles/images/computing.png"), url("../../styles/images/integrated.png"), url("../../styles/images/chipset.png");
    background-repeat: no-repeat;
    background-position: right -5% top 5%, left -15% top 35%, right -41% top 62%, left -28% top 90% ;
    background-size: 15%, 28%, 40%, 30%;
}

.softwares h1 {
    margin: 5% 0 10% 0;
    font-size: 2.2em;
    text-align: center;
    color: var(--primary-color);
}


.software-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
}

.software-description h2, #depot {
    align-self: flex-start;
    border: 3px solid var(--secondary-color);
    padding: 0.75% 2% 0.75% 2%;
    text-align: center;
    border-radius:50px;
    margin-bottom: 2%;
    color: var(--primary-color);
}
/* -------------------------------------------- G2POS ------------------------------------------------ */


.g2pos-classic {
    margin: auto;
}

.g2pos-text {
    margin: 3% 0% 5% 0%;
    width: 55vw;
    display: flex;
    flex-direction: column;
}

.g2pos-rest h4 {
    text-align: center;
    margin-bottom: 3%;
    font-size: 1.2em;
    color: var(--primary-color);
}


.restaurant-images {
    width: 70vw;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 2% 5% 2% 5%;
    grid-template-columns: repeat(3, 1fr);
}

.restaurant-images img {
    border: 1px solid lightgrey;
    border-radius: 12px;
    width: 17vw;
    cursor: pointer;
    
}

.restaurant-images img:hover {
    z-index: 9999;
    transform: scale(2);
    box-shadow: 0 0 0 max(100vh, 100vw) rgba(0, 0, 0, 0.5);
}


/* -------------------------------------------- DEPOT SOFT ------------------------------------------------ */


.depot-desktop {
    align-items: center;
    flex-direction: column;
    display: flex;
    margin-bottom: 10%;
}

.depot-phone {
    margin-left: -8%;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    gap: 5%;
}

.depot-phone img {
    width: 6vw;
    border-radius: 18px;
}

.phone-view {
    padding: 2% 0% 2% 5%;
    display: flex;
    gap: 5%;
    margin-right: 5%;
}


.depot-tablet {
    display: flex;
    gap: 5%;
}

.depot-tablet img {
    border-radius: 0px;
    width: 18vw;
}

/* -------------------------------------------- OTHER ------------------------------------------------ */
.other {
    display: flex;
    align-items: center;
}

.other-soft {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    padding: 0% 5% 0% 5%;
}

.other-soft img {
    border: 2px solid lightgrey;
    border-radius:12px;
}

.other-soft img:hover {
    cursor: pointer;
    z-index: 9999;
    transform: scale(1.3);
    box-shadow: 0 0 0 max(100vh, 100vw) rgba(0, 0, 0, 0.5);
}

.other-text {
    display: flex;
    align-items: center;
}


.custom-quote a {
    color: var(--secondary-color);
}

/* ------------------------------------------- GOFFICE ------------------------------------------ */

 .goffice-suite figure {
    padding: 3% 0 10% 0;
    display: grid;
    gap: 5%;
    grid-template-columns: repeat(3, 1fr);
}

figure img {
    width: 15vw;
    height: 20vh;
}

.goffice-suite figcaption {
    text-align: center;
}

@media screen and (max-width:450px) {

    .g2pos-text p:first-of-type {
        margin-top: 10%;
    }

    .g2pos-text p {
        width: 65vw;
    }

    .g2pos-text p:last-of-type {
        margin: 15% 0 20% 0;
    }

    .depot-phone {
        margin-left: 10%;
        flex-direction: column;
        gap: 5vh;
    }

    
    .depot-phone img {
        border-radius:0;
        width: 22vw;
    }
    
    .depot-tablet {
        flex-direction: column;
        gap: 5vh;
    }

    .depot-tablet img {
        width: 45vw;
    }
    .softwares {
        padding: 0 5% 0% 5%;
    }

    .softwares h1 {
        margin: 5% 0 10% 0;
        font-size: 1.5em;
        text-align: center;
        color: var(--primary-color);
    }

    figure img {
        width: 40vw;
    }
    .goffice-suite figure {
        padding: 3% 0 20% 0;
        display: grid;
        gap: 5%;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
    }
}