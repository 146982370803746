.admin {
    min-height: 80vh;
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../styles/images/circuit.png"), url("../../styles/images/computing.png"), url("../../styles/images/integrated.png"), url("../../styles/images/chipset.png");
    background-repeat: no-repeat;
    background-position: right -5% top 5%, left -10% top 35%, right -25% top 80%, left -15% top 117% ;
    background-size: 15%, 20%, 30%, 20%;
}