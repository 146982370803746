.contact {
    padding-top: 10%;
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../styles/images/circuit.png"), url("../../styles/images/computing.png"), url("../../styles/images/integrated.png"), url("../../styles/images/chipset.png");
    background-repeat: no-repeat;
    background-position: right -5% top 5%, left -10% top 35%, right -25% top 80%, left -15% top 117% ;
    background-size: 15%, 20%, 30%, 20%;
}

.contact h1 {
    color:var(--primary-color);
    margin:5% 0 10% 0;
}

.contact-form {
  width: 35vw;
  border: 2px solid var(--secondary-color);
  border-radius: 12px;
  padding: 1.5% 2% 1.5% 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.validate-form {
    margin: 5% 0 5% 0%;
    border-radius: 8px;
    width: 20vw;
    height: 5vh;
    border: none;
    color: white;
    background-color: var(--secondary-color);
    cursor: pointer;
    font-weight: 700;
    transition: all 250ms;
  }

  .validate-form:hover {
    background-color: var(--primary-color);
  }

  .inputs {
    display: flex;
    gap: 15px;
    width: 20vw;
    flex-direction: column;
  }

  ::placeholder {
    color: black;
  }


  .inputs input, select {
    background-color: var(--input-color);
    height: 4.5vh;
    padding-left: 5%;
    border: none;
    border-radius: 8px;
  }
  
  .inputs textarea {
    background-color: var(--input-color);
    font-family: inherit;
    padding: 2% 5%;
    border: none;
    border-radius: 8px;
    resize: none;
  }

  .orange-text {
    color: var(--secondary-color);
  }

  @media screen and (max-width: 534px) {

    .send {
      margin-left: 5%;
    }

    h3 {
      font-size:0.8em;
    }

    h1 {
      text-align: center;
      font-size: 1.5em;
    }
    .contact-form {
      width: 80vw;
    }

    .validate-form {
      width: 70vw;
    }

    .inputs {
      width: 70vw;
    }
}