header {
    display: flex;
    /* justify-content: space-between; */
    justify-content:space-between;
    height: 10vh;
    padding: 1%;
    border-bottom: 1px solid #072c502c;
}

.nav-menu {
    display: none;
}

.menu-logo {
    display: none;
}

header img {
    cursor: pointer;
}

header a {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 600;
}


header a:hover {
    color: var(--secondary-color);
}

.logo-nav {
    padding-left: 4%;
    display: flex;
    gap: 5%;
}

.logo-nav img {
    margin: auto;
    width: clamp(120px, 7vw, 512px);
}

nav {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center ;
    justify-items: center;
    gap: 2%;
    /* width: 27vw; */
    font-size: clamp(14px, .8vw, 2vw);
}


.contact-us {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    /* gap: 2%; */
}


@media screen and (max-width: 805px) {

    .nav-menu {
        z-index: 99999;
        position: fixed;
        right: -100%;
        top: 0;
        height: 200vw;
        width: 80vw;
        background-color: var(--primary-color);
        color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1.5em;
        gap: 3%;
        padding: 6% 3% 0 5%;
        transition: 850ms;
    }

    .nav-menu a {
        text-align: center;
        padding: 3% 0% 3% 1%;
        color: white;
        margin: 5% 0 0 15%;
    }
    

    .nav-menu.active {
        right: 0;
        transition: 350ms;
    }

    .close-menu {
        height: 5px;
        border-radius: 8px;
        width: 32px;
        background-color: white;
    }

    .close-menu:hover {
        background-color: var(--secondary-color)
    }
    
    .menu-resp-div {
        cursor: pointer;
        padding: 3%;
        align-self: flex-end;

    }

    nav, .contact-us {
        display: none;
    }
    
    .logo-nav img {
        width: clamp(100px, 17vw, 512px);
    }

    .menu-logo {
        padding-right: 4%;
        display: block;
        height: 5vh;
        align-self: center;
        cursor: pointer;
    }
}

@media screen and (max-width: 534px) {
    .nav-menu.active {
        font-size: 1.4em;
        width:80vw;
    }
}