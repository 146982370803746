.dashboard {
    display: flex;
    align-items: flex-start;
    min-height: 80vh;
    color: var(--primary-color);
    background-image: url("../../styles/images/circuit.png"), url("../../styles/images/computing.png"), url("../../styles/images/integrated.png"), url("../../styles/images/chipset.png");
    background-repeat: no-repeat;
    background-position: right -5% top 5%, left -10% top 35%, right -25% top 60%, left -15% top 90% ;
    background-size: 15%, 20%, 30%, 20%;
    padding: 5%;
}

.dashboard-btn {
    margin-top: 8%;
    color: var(--secondary-color);
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.dashboard section {
    display: flex;
    width: 50vw;
    flex-direction: column;
    gap: 1vh;
    margin-right: 3%;;
    border: 1px solid lightgrey;
    padding: 3%;
    background-color: rgb(236, 236, 236);
}

.admin-products {
    margin: 3% 0 3% 0;
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.admin-products h3 {
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 2%;
}

.products-cat {
    background-color: white;
    padding: 2%;
}

.admin-products-listing {
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.add-product {
    transition: all 250ms;
    cursor: pointer;
    width: 3vw;
}

.add-product:hover {
    transform: scale(1.1);
}

.edit-listing img{
    width: 2%;
    cursor: pointer;
    object-fit: contain;
}

.pop-up {
    margin-left: 2%;
    display: flex;
    gap: 1vw;
}

.pop-up p {
    color: var(--secondary-color);
    text-decoration: underline;
}

.buttons {
    display: flex;
    gap: 1vw;
}

.buttons button {
    cursor: pointer;
    border: none;
    background-color: inherit;
    font-weight: 900;
}