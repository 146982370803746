.loader {
    margin: auto;
    height: 512px;
    width: 512px;
    background-image: url('../../styles/images/loading.png');
    animation: rotate 1s infinite linear;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg) scale(0.2);
    }
  
    to {
      transform: rotate(360deg) scale(0.2);
    }
  }
  