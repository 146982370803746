footer {
    display: flex;
    width: 98.65%;
    flex-direction: column;
    align-items: center;
    color: white;
}

footer section {
    border-top: 1px solid lightgrey;
    height: 16vh;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(5, 1fr); 
    padding: 0.5% 0% 0.5% 0%;
    align-items: center;
    align-content: center;
    background-color: var(--primary-color);
}

.company {
    display: flex;
    grid-column-start: 1;
    grid-column-end: 3;
    gap: 20%;
    align-items: center;
    justify-content:center;
    /* width: 30%; */
}

.sitemap {
    display: flex;
    grid-column-start: 4;
    grid-column-end: 6;
    gap: 5%;
    justify-content: center;
}

.back-to-top {
    margin-top: -5%;
    padding: 0.5% 1.5%;
    height: 50%;
    border: none;
    background-color: #E09C17;
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    border-radius:6px;
    cursor: pointer;
}

/* .logo-round {
    display: flex;
    align-items: center;
    justify-content: center;
} */

.logo-round  {
    transform: scale(0.17);
}

.sitemap a {
    color: white;
    text-decoration: none;
    font-weight: 600;
}

.sitemap a:hover {
    color: #E09C17;
    text-decoration: underline;
}

.copyright {
    height: 5vh;
    background-color: var(--primary-color);
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0% 40% 2% 40%;
    color: white;
}


.copyright a {
    color: white;
    text-decoration: none;
}

.copyright a:hover {
    color: var(--secondary-color);
}

@media (max-width: 1513px) {
    .copyright {
        padding: 0.5% 15% 0.5% 15%;
    }

    .company p, .copyright a {
        margin: 0 3% 0 3%;
    }
}

@media screen and (max-width: 1115px) {

    .footer-bar {
        display: none;
    }

    .copyright {
        display: grid;
        align-content: center;
        justify-items: center;
        grid-template-columns: repeat(8, 1fr);
        height: 10vh;
        padding: 0.5% 5% 0.5% 6.5%;
    }

    .copyright img {
        grid-column-start: 4;
        grid-column-end: 6;
    }

    .company, .sitemap {
        font-size: 0.9em;
    }

    footer section {
        height: 22vh;
        padding: 1% 5% 1% 5%;
        width: 100vw;
        display: flex;
        justify-content: space-between;
    }

    .company {
        gap: 10%;
    }
}

@media screen and (max-width:789px) {
    footer section {
        flex-direction: column;
    }

    .company {
        margin-top: 3%;
        gap: 60%;
    }

    .sitemap {
        align-items: center;
        justify-content: normal;
    }

    .back-to-top {
        margin-left: 20%;
        margin-top: -50%; 
        height: 3vh;
    }

}

@media screen and (max-width: 590px) {
    .back-to-top {
        margin-left: -70%;
        margin-top: -70%; 
        height: 3vh;
    }
}

@media screen and (max-width: 425px) {
    footer section, .copyright {
        font-size: 0.9em;
    }
}

@media screen and (max-width: 385px) {
    .copyright {
        height: 15vh;
        font-size: .8em;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-content: space-evenly;
        padding: 0 3% 0% 3%;
    }

    .copyright img {
        width: 10vw;
        transform: scale(1)
    }
}