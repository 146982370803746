.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--secondary-color);
    border-radius: 12px;
    width: 25vw;
    padding: 5% 0 6% 0;
}

.login-container {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.login-textBox {
    background-color: var(--input-color);
    height: 4.5vh;
    width: 15vw;
    padding-left: 5%;
    border: none;
    border-radius: 8px;
}

.login-btn {
    margin: 5% 0 5% 0%;
    border-radius: 8px;
    width: 15vw;
    height: 5vh;
    border: none;
    color: white;
    background-color: var(--secondary-color);
    cursor: pointer;
    font-weight: 700;
    transition: all 250ms;
}

.reset-pass {
    align-self: flex-end;
}

.reset-pass a {
    color: var(--secondary-color);
}