.about {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../styles/images/circuit.png"), url("../../styles/images/computing.png"), url("../../styles/images/integrated.png"), url("../../styles/images/chipset.png");
    background-repeat: no-repeat;
    background-position: right -5% top 5%, left -15% top 35%, right -41% top 62%, left -28% top 90% ;
    background-size: 15%, 28%, 40%, 30%;
}

.about h1 {
    margin: 7% 0% 5% 2%;
    color: var(--primary-color);
    font-size: 2.5em;
}

.about-card {
    display: flex;
    flex-direction: column;
    padding-left: 8%;
    width: 60vw;
}

.about-text {
    width: 50vw;
    margin: 0 0 5% 0%;
    font-size: 1.2em;
}

.experience {
    font-weight: 700;;
}

.pcsoft a {
    color: var(--secondary-color);
    margin-left: 1%;
}

.pcsoft-logo {
    margin-top: 5%;
    display: flex;
    justify-content:space-around;
    padding: 3% 0 3% 0;
}
.pcsoft img {
    object-fit: contain;
}

.windev-suite {
    display: grid;
    gap: 15%;
}

.windev-suite img {
    width: 13vw;
}

.soft-example p {
    text-align: center;
}

.soft-img img {
    width: 15vw;
}

.soft-example img {
    margin-top: 15%;
    border-radius: 12px;
    border: 1px solid lightgrey;
}

@media screen and (max-width: 534px) {

    .pcsoft-logo img {
        width: 35vw;
    }

    .about-card {
        width: 85vw;
    }

    .about .ask-quote {
        width: 75vw;
    }

    .soft-example {
        flex-direction: column;
    }

    .soft-example div {
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .soft-img img {
        width: 65vw;
    }
}