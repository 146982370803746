.product-card {
    background-color: rgb(233, 233, 233);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 45vh; */
    width: 20vw;
    padding: 3% 2% 2% 2%;
    border-radius: 12px;
    color: #072C50;
    font-size: 1.2em;
    transition: all 300ms;
    cursor: pointer;
}

.product-card:hover {
    transform: scale(1.1);
}

.scanner-cards, .printer-cards, .drawer-cards {
    margin-top: 50%;
    justify-self: flex-end;
}