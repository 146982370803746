@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100&display=swap');

:root {
  --primary-color: #072C50;
  --secondary-color: #E09C17;
  --input-color: rgb(255, 240, 208);
}

* {
  font-family: 'Lato', sans-serif;
  padding: 0;
  margin:0;
  box-sizing: border-box;
}

.app {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  margin: auto;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  max-width: 70%;
  transition: all 200ms;
}

@media screen and (max-width: 1500px) {
    .app {
      max-width: 80%;
    }
  }
  
  @media screen and (max-width: 1125px) {
    .app {
      max-width: 100%;
      border: none;
  }
}