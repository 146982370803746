.add-prod {
    display: flex;
    gap: 5vh;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
    color: var(--primary-color);
    background-image: url("../../styles/images/circuit.png"), url("../../styles/images/computing.png"), url("../../styles/images/integrated.png"), url("../../styles/images/chipset.png");
    background-repeat: no-repeat;
    background-position: right -5% top 5%, left -10% top 35%, right -25% top 60%, left -15% top 90% ;
    background-size: 15%, 20%, 30%, 20%;
    padding: 5%;
}

.add-prod form {
    width: 35vw;
    padding: 5% 3% 5% 3%;
    display: flex;
    gap: 1vh;
    background-color: rgb(236, 236, 236);
    flex-direction: column;
    border: 1px solid lightgrey;
}

::placeholder {
    color: grey;
}

.add-prod form input {
    height: 4.5vh;
    padding-left: 5%;
    border: none;
    border-radius: 8px;
    background-color: white;
}

.add-prod form textarea {
    font-family: inherit;
    padding: 2% 5%;
    border: none;
    border-radius: 8px;
    resize: none;
}

.add-product-btn {
    margin: 1% 0 5% 0%;
    border-radius: 8px;
    width: 10vw;
    height: 5vh;
    border: none;
    color: white;
    background-color: var(--secondary-color);
    cursor: pointer;
    font-weight: 700;
    transition: all 250ms;
}

.radio-inputs {
    display: flex;
    justify-content: center;
    gap: 3vw;
    margin-bottom: 3%;
}

.input-type {
    display: flex;
    flex-direction: column;
    align-items:center;
}

.product-added {
    text-align: center;
    margin-top: 3%;
    color: var(--secondary-color);
    background-color: white;
    border-radius: 8px;
    padding: 3% 2% 3% 2%;
}
