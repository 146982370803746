.peripheral {
    min-height: 80vh;
    padding: 3% 0 5% 0;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    background-image: url("../../styles/images/circuit.png"), url("../../styles/images/computing.png"), url("../../styles/images/integrated.png"), url("../../styles/images/chipset.png");
    background-repeat: no-repeat;
    background-position: right -5% top 5%, left -15% top 35%, right -41% top 62%, left -28% top 90% ;
    background-size: 15%, 28%, 40%, 30%;
}

.peripheral img {
    width: 10vw;
    margin-right: 5%;
    height: 18vh;
    object-fit: contain;
}


.peripheral h2:not(.ask-quote h2){
    margin: 0 0% 20% 25%;
    text-align: center;
    width: 13vw;
    background-color: white;
    border: 3px solid var(--secondary-color);
    border-radius:15px;
    padding: 2% 1% 2% 1%;
    color: var(--primary-color);
}

.peripheral section {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}

.peripheral h1 {
    color: var(--primary-color);
    font-size: 2.2em;
    text-align: center;
    margin-bottom: 5%;
}

.cashregist-card, .scanner-product-card, .drawer-card, .accessory-card {
    display: flex;
    padding: 2% 5% 2% 5%;
}

.card-text {
    display: flex;
    flex-direction: column;
    gap: 10%;
}

.cash-register-page.card-text {
    width: 35vw;
}

.peripheral-card {
    padding-left: 10%;
    width: 50vw;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 534px) {

    .peripheral h2:not(.ask-quote h2) {
        width: 45vw;
    }
    
    .peripheral .cashregist-card img, .scanner-product-card img, .drawer-card img, .accessory-card img {
        width: 55vw;
    }

    .peripheral .cashregist-card, .scanner-product-card, .drawer-card, .accessory-card{
        margin-left: 25%;
        align-items: center;
        flex-direction: column;
        width: 55vw;
    }

    .peripheral .ask-quote {
        width: 80vw;
    }
}