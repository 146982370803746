.home {
    margin: 3% 0% 3% 0%;
    min-height: 80vh;
    background-image: url("../../styles/images/circuit.png"), url("../../styles/images/computing.png"), url("../../styles/images/integrated.png"), url("../../styles/images/chipset.png");
    background-repeat: no-repeat;
    background-position: right -7% top 2%, left -15% top 20%, right -41% top 60%, left -28% top 80% ;
    background-size: 15%, 28%, 40%, 30%;
}

.home h2 {
    font-size: 1.8em;
    color: #072C50;
}

.photos-introduction {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
}

.home-introduction-text {
    padding-left: 5%;
    align-items: center;
    gap: 15%;
    display: flex;
    margin-bottom: 5%;
}

.home-introduction-text img {
    width: 19vw;
    border-radius: 25px;
    height: auto;
}

.home-introduction-text h2 {
    width: 30vw;
    font-size: 2.3em;
}

.ask-quote {
    display: flex;
    align-items: center;
    width: 30vw;
    height: 10vh;
    gap: 5%;
}

.photo-quote {
    display: flex;
    gap: 7%;
    align-items: center;
    padding-left: 5%;
}

.contact-quote-btn {
    height: 5vh;
    padding: 2.5% 3% 2.5% 3%;
    border: none;
    background-color: var(--secondary-color);
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-weight: 800;
    font-size: 1.1rem;
    transition: all 250ms;
}

.contact-quote-btn:hover {
    transform: scale(1.05)
}

.contact-quote-btn a {
    text-decoration: none;
    color: white;
}

/*--------------------- SOFTWARE SECTION -------------------------------------- */

.software {
    display: flex;
    padding: 1% 5% 1% 5%;
    flex-direction: column;
}

.software h2 {
    align-self: center;
    margin-bottom: 5%;;
    font-size: 2.3em;
}

.software-product {
    margin-bottom: 10%;
    width: 45vw;
    display: flex;
    align-items: center;
    gap: 5%;
    font-size: 1.2em;
}

.software-text {
    display: flex;
    flex-direction: column;
}

.software-product a, .ios-software a, .products a {
    color: #E09C17;
    text-decoration: none;
    align-self: flex-end;
}

.software-product p {
    padding-bottom: 3%;
    width: 20vw;
}

.software-product img {
    width: 100%;
    border: 2px solid lightgrey;
}

.software-product:nth-child(even) {
    align-self: flex-end;
}

.software-product:nth-child(odd) {
    align-self: flex-start;
}

.devices {
    display: flex;
    align-items: center;
    gap: 5%;
}

.ios-software {
    margin: 5% 0 5% 0;
    align-self: center;
    width: 49vw;
    display: flex;
    align-items: center;
    gap: 15%;
    font-size: 1.2em;
}

/* --------------------------- PRODUCTS ------------------------------- */

.products {
    display: flex;
    flex-direction: column;
}

.products h2 {
    align-self: center;
    margin-bottom: 5%;;
    font-size: 2.3em;
}

.product.left {
    display: flex;
    margin-left: 10%;
    align-items: center;
}

.product.right {
    display: flex;
    margin-left: 30%;
    margin-bottom: 8%;
    align-items: center;
}

.product-text {
    display: flex;
    flex-direction: column;
    gap: 5%;
    height: 15vh;
}

.product-card img {
    width: 8vw;
}

.product-text h3 {
    font-size: 1.5em;
}

.cards {
    display: flex;
    justify-content:center;
    gap: 5%;
    margin-bottom: 5%;
}
@media screen and (max-width:450px) {

    .home-introduction-text {
        flex-direction: column;
    }

    .home-introduction-text img {
        margin:3% 0 5% 0;
        width: 40vw;
        height: 15vh;
    }

    .home h2 {
        font-size: 1.3em;
        width: 55vw;
        color: #072C50;
    }

    .photo-quote {
        margin-top: 5%;
        margin-bottom: 5%;
        flex-direction: column;
    }

    .ask-quote {
        margin-bottom: 3%;
        font-size: 0.9em;
        width: 70vw;
    }

    .contact-quote-btn {
        font-size: 0.9em;
        padding: 2% 3% 2% 3%;
        width: 50vw;
    }

    /* ----------------------- SOFT ------------------- */

    .software-product:nth-child(even), .software-product:nth-child(odd){
        align-self: center;
    }

    .software-product {
        margin-top: 10%;
        font-size: 1em;
        flex-direction: column;
    }

    .software-product img {
        margin-top: 5%;
        width: 60vw;
    }

    .software-text p {
        width: 65vw;
    }

    .ios-software {
        margin-top: 10%;
        flex-direction: column;
    }

    .ios-software p {
        margin-top: 10%;
        font-size: 0.9em;
        width: 65vw;
    }

    .products h2 {
        font-size: 1.3em;
        width: 68vw;
    }

    .product h3 {
        font-size: 1.1em;
    }

    .product p {
        font-size: 0.9em;
    }

    .product.left {
        justify-content: center;
        margin: 5% 0% 5% 0%;

    }

    .product.right {
        justify-content: center;
        margin: 5% 0% 5% 5%;
    }

    .cards, .our-peripheral {
        display: none;
    }
}